import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import marked from "marked"
import Head from "../components/head"
import PageLayout from "../components/apcpagelayout"

const Aboutkafka = props => {
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
  })
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        title
        bannerBackground {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <PageLayout
      title="ABOUT KAFKA"
      backgroundImage={data?.contentfulHomePage?.bannerBackground}
    >
      <Head
        title="About Apache Kafka®"
        description="About Apache Kafka® - Apache Kafka® is an open source  distributed streaming platform that used for building real-time data pipelines and streaming apps. It is horizontally scalable, fault-tolerant, wicked fast, and runs in production in thousands of companies.Check out some of the awesome companies running Kafka and their use cases here: https://kafka.apache.org/powered-by.Apache Kafka®, Kafka, and associated open source project names are trademarks of the Apache Software Foundation. - Date TBD - Location TBD"
      />
      <div className="about_kafka_body">
        <div className="about_kafka_body_data">
          <h2>What is Apache Kafka?</h2>
        </div>
        <div
          className="about_kafka_body_data"
          // style={{ whiteSpace: "pre-wrap" }}
          // dangerouslySetInnerHTML={getMarkdownText(
          //   data?.contentfulAboutKafka?.description?.description
          // )}
        >
          <p>
            <a
              target="_blank"
              href="https://kafka.apache.org/"
              rel="noreferrer"
            >
              Apache Kafka®
            </a>{" "}
            is an open source distributed streaming platform that used for
            building real-time data pipelines and streaming apps. It is
            horizontally scalable, fault-tolerant, wicked fast, and runs in
            production in thousands of companies.
          </p>
          <br />
          <p>
            Check out some of the awesome companies running Kafka and their use
            cases here:
            <a
              target="_blank"
              href="https://kafka.apache.org/powered-by"
              rel="noreferrer"
            >
              https://kafka.apache.org/powered-by
            </a>
          </p>
          <br />
          <p>
            Apache Kafka®, Kafka, and associated open source project names are
            trademarks of the Apache Software Foundation.
          </p>
          <br />
          <p>
            Attendees of Kafka Summit are expected to abide by the code of
            conduct.
          </p>
        </div>
      </div>
    </PageLayout>
  )
}
export default Aboutkafka
